<template>
  <div>
    <label class="input-field-label">Upload a Document</label>
    <div class="relative group">
      <div
        :class="[
          {
            'bg-orange-200 border-primary text-orange-900 scale-95': isDragingOver,
          },
          ' transform h-40 w-full text-sm bg-gray-100 border border-gray-400 group-hover:border-primary p-2 rounded group-hover:shadow active:shadow leading-tight cursor-pointer flex flex-col justify-center items-center transition duration-200',
        ]"
      >
        <svg
          class="stroke-current h-8 w-8"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
        >
          <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z" />
          <path d="M14 2v6h6M12 18v-6M9 15h6" />
        </svg>
        <p class="text-lg font-bold text-center">
          {{ tempFile ? tempFile[0].name : "Drag a document here to upload." }}
        </p>
        <p v-if="!tempFile" class="text-sm">
          Or click to open the file picker.
        </p>
      </div>
      <input
        ref="fileInput"
        @dragover.prevent="isDragingOver = true"
        @dragleave.prevent="isDragingOver = false"
        @drop.prevent="dropFile"
        @change="onFileChange"
        type="file"
        class="absolute opacity-0 inset-0 w-full h-full cursor-pointer"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "FileUpload",
  props: {
    file: FileList,
  },
  data() {
    return {
      isDragingOver: false,
      tempFile: null,
    };
  },
  methods: {
    onFileChange: function() {
      this.tempFile = this.$refs.fileInput.files;
      this.$emit("update:file", this.$refs.fileInput.files);
      console.log(
        "[FileUpload] onFileChange: This is the new file: ",
        this.$refs.fileInput.files
      );
    },
    dropFile: function(event) {
      this.isDragingOver = false;
      this.$refs.fileInput.files = event.dataTransfer.files;
      this.onFileChange();
    },
  },
};
</script>
